import {ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

export class PGLocationName extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([ClueInputType.LocationName]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    const text = <ClueInputType.LocationName>clueInput.payload;

    this.clear(ctx);
    this.drawClueNumber(context, context.index);

    // draw text
    ctx.fillStyle = '#000';
    this.setSmallText(ctx);
    this.wrapText(ctx, text, 20, 100, 400, 20);
  }
}
