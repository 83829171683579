import {ClueInput, ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

function getClueText(clueInput: ClueInput): string {
  switch (clueInput.type) {
    case ClueInputType.LocationName:
      return clueInput.payload;
    case ClueInputType.Riddle:
      return clueInput.payload;
    case ClueInputType.ShortWords:
      return clueInput.payload.join(' ');
    default:
      throw new Error(`Clue Type ${clueInput.type} not allowed for puzzle.`);
  }
}

export class PGPigPen extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    ClueInputType.LocationName,
    ClueInputType.Riddle,
    ClueInputType.ShortWords,
  ]);

  cachedImage?: HTMLImageElement;

  async loadPigPenImage(): Promise<HTMLImageElement> {
    if (!this.cachedImage) {
      this.cachedImage = new Image();
      this.cachedImage.src = '/assets/pigpen.png';
      await new Promise(resolve => (this.cachedImage!.onload = resolve));
    }

    return this.cachedImage;
  }

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    const text = getClueText(clueInput);

    this.clear(ctx);

    ctx.font = '20px PigPen';

    this.drawClueNumber(context, context.index);
    this.setPigPenFont(ctx);

    ctx.fillStyle = this.theme.textColor;
    this.wrapText(ctx, text, 15, 100, 400, 20);

    const img = await this.loadPigPenImage();
    ctx.drawImage(img, 30, 200);
  }
}
