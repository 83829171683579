import {Table} from '../lib/table';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';
import shuffle from 'lodash/shuffle';
import {ClueInputType} from '../clue-locations';

const debug = window.location.hostname === 'localhost' && false;

const fontSpacing = 24;
const fontOffsetX = 230;
const fontOffsetY = 60;

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const randomCharacter = () =>
  alphabet[Math.floor(Math.random() * alphabet.length)];

interface Vector2 {
  x: number;
  y: number;
}

export class WordSearchGenerator {
  width: number;
  height: number;
  words: string[];

  table: Table<string>;
  isWordLetterTable: Table<boolean>;

  directions = [
    // {x: -1, y: 0}, // W
    // {x: -1, y: -1}, // NW
    // {x: 0, y: -1}, // N
    {x: 1, y: -1}, // NE
    {x: 1, y: 0}, // E
    {x: 1, y: 1}, // SE
    {x: 0, y: 1}, // S
    // {x: -1, y: 1}, // SW
  ];
  constructor(width: number, height: number, words: string[]) {
    this.width = width;
    this.height = height;
    this.words = words
      .slice()
      .map(x => x.toUpperCase())
      .sort((a, b) => b.length - a.length);

    this.table = new Table<string>(width, height);
    this.isWordLetterTable = new Table(width, height);

    this.addWords();
    this.fillRandomChars();
  }

  isWordLetter(pos: Vector2) {
    return this.isWordLetterTable.get(pos);
  }

  getTable(): Table<string> {
    return this.table.clone();
  }

  clearTable() {
    this.isWordLetterTable.fill(false);
    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.height; y++) {
        this.table.clear({x, y});
      }
    }
  }

  addWords(attemptsLeft = 10): void {
    this.clearTable();
    if (attemptsLeft === 0) throw new Error('Could not create word search!');
    for (const word of this.words) {
      const added = this.addWord(word);
      if (!added) {
        return this.addWords(attemptsLeft - 1);
      } else {
        // console.log('added ' + word);
      }
    }
  }

  addWord(word: string): boolean {
    const randDirections = shuffle(this.directions.slice());
    const positions = new Array<Vector2>();

    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.height; y++) {
        positions.push({x, y});
      }
    }

    const randPositions = shuffle(positions);

    for (const d of randDirections) {
      for (const s of randPositions) {
        const added = this.tryToAddWordInDirection(word, s, d);
        if (added) return true;
      }
    }

    return false;
  }

  private tryToAddWordInDirection(
    word: string,
    start: Vector2,
    direction: Vector2
  ): boolean {
    let x = start.x;
    let y = start.y;

    for (let i = 0; i < word.length; i++) {
      if (x < 0 || x >= this.width) return false;
      if (y < 0 || y >= this.height) return false;

      const existing = this.table.get({x, y});
      if (existing && existing !== word[i]) return false;

      x = x + direction.x;
      y = y + direction.y;
    }

    x = start.x;
    y = start.y;

    for (let i = 0; i < word.length; i++) {
      this.table.set({x, y}, word[i]);
      this.isWordLetterTable.set({x, y}, true);
      x = x + direction.x;
      y = y + direction.y;
    }

    return true;
  }

  private fillRandomChars() {
    for (let x = 0; x < this.table.width; x++) {
      for (let y = 0; y < this.table.height; y++) {
        if (this.table.get({x, y}) === undefined) {
          this.table.set({x, y}, randomCharacter());
        }
      }
    }
  }
}

export class PGWordsearch extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    ClueInputType.FillInTheBlanks,
    ClueInputType.ShortWords,
  ]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    let displayText = '';
    let words = new Array<string>();

    if (clueInput.type === ClueInputType.FillInTheBlanks) {
      displayText = clueInput.payload.blanksText;
      words = clueInput.payload.blanksWords;
    } else if (clueInput.type === ClueInputType.ShortWords) {
      displayText = "Find the next location's name in the wordsearch";
      words = clueInput.payload;
    } else {
      throw new Error(`Crossword can't accept ${clueInput.type} clueInputType`);
    }

    const wsGen = new WordSearchGenerator(10, 10, words);
    const ws = wsGen.getTable();

    this.clear(ctx);

    ctx.font = this.theme.font;
    ctx.fillStyle = this.theme.textColor;
    ctx.textAlign = 'center';

    // Draw lefthand size
    this.drawClueNumber(context, context.index);
    ctx.textAlign = 'left';
    ctx.font = this.smallText;
    this.wrapText(ctx, displayText, 15, 100, 160, 20);

    // Draw line
    this.drawVertLine(context);

    // Draw crossword
    ctx.font = this.smallMonoText;
    ctx.textAlign = 'center';
    for (let y = 0; y < ws.height; y++) {
      for (let x = 0; x < ws.width; x++) {
        const rx = x * fontSpacing + fontOffsetX;
        const ry = y * fontSpacing + fontOffsetY;
        const isWordLetter = wsGen.isWordLetter({x, y});

        if (debug && isWordLetter) {
          ctx.fillStyle = '#888';
          ctx.fillText(ws.get({x, y}) || 'OOPS', rx, ry);
        } else {
          ctx.fillStyle = '#000';
          ctx.fillText(ws.get({x, y}) || 'OOPS', rx, ry);
        }
      }
    }

    // ctx.fillText('foo', 0, 10);
  }
}
