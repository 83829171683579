import shuffle from 'lodash/shuffle';
import {ClueInputType, ClueLocation, ClueLocationData} from '../clue-locations';
import {
  filterInputsByPuzzleType,
  getPuzzleTypesForLocation,
  PuzzleType,
} from '../puzzle-generators';
import {ClueDesign} from './clue-design';

export enum Difficulty {
  Easy = 'Easy', // Only location names + normal riddles
  Medium = 'Medium', // Only riddles, or simple location names in puzzles
  Hard = 'Hard', // Allow for everything
}

export abstract class ClueGenerator {
  abstract randomizePuzzleType(clueDesign: ClueDesign): ClueDesign;
  abstract randomClueDesignFromLocation(location: ClueLocation): ClueDesign;

  addNewRandomClueDesign(clues: ClueDesign[]): ClueDesign {
    const location = this.getRandomUnusedLocation(clues);
    const clueDesign = this.randomClueDesignFromLocation(location);
    return clueDesign;
  }

  randomizeLocation(clues: ClueDesign[], clueDesign: ClueDesign): ClueDesign {
    const location = this.getRandomUnusedLocation(clues);
    const clue = this.randomClueDesignFromLocation(location);
    return clue;
  }

  randomizeClueTrail(length: number): ClueDesign[] {
    const randomLocations = shuffle(ClueLocationData.slice()).slice(0, length);

    const clues = randomLocations.map(l =>
      this.randomClueDesignFromLocation(l)
    );
    return clues;
  }

  getRandomUnusedLocation(clues: ClueDesign[]): ClueLocation {
    const inUse = clues.map(x => x.solutionLocation.locationName);
    const availableLocations = ClueLocationData.filter(
      x => !inUse.includes(x.locationName)
    );
    return shuffle(availableLocations)[0];
  }

  getRandomPuzzleTypeWithAversion(
    puzzleTypes: PuzzleType[],
    aversions: PuzzleType[]
  ): PuzzleType {
    const puzzleList = [
      ...shuffle(puzzleTypes).filter(
        type => aversions.includes(type) === false
      ),
      ...aversions,
    ];

    return puzzleList[0];
  }
}

export class EasyClueGenerator extends ClueGenerator {
  getFilteredPuzzlesForLocation(location: ClueLocation) {
    return getPuzzleTypesForLocation(location).filter(x =>
      [PuzzleType.riddle, PuzzleType.locationName].includes(x)
    );
  }

  randomizePuzzleType(current: ClueDesign): ClueDesign {
    const puzzleTypes = this.getFilteredPuzzlesForLocation(
      current.solutionLocation
    );

    const puzzleType = this.getRandomPuzzleTypeWithAversion(puzzleTypes, [
      current.puzzleType,
    ]);

    const clueInputs = filterInputsByPuzzleType(
      puzzleType,
      current.solutionLocation.clueInputs
    );

    const clueInput = shuffle(clueInputs)[0];

    const clueDesign: ClueDesign = {
      solutionLocation: current.solutionLocation,
      puzzleType,
      clueInput,
    };
    return clueDesign;
  }

  randomClueDesignFromLocation(location: ClueLocation): ClueDesign {
    // Only accept puzzles that are either location name or riddle
    const availablePuzzles = getPuzzleTypesForLocation(location);
    const puzzleType = availablePuzzles.includes(PuzzleType.riddle)
      ? PuzzleType.riddle
      : PuzzleType.locationName;

    const clueInputs = filterInputsByPuzzleType(
      puzzleType,
      location.clueInputs
    );
    const clueInput = shuffle(clueInputs)[0];

    return {
      solutionLocation: location,
      puzzleType,
      clueInput,
    };
  }
}

export class MediumClueGenerator extends ClueGenerator {
  getFilteredPuzzlesForLocation(location: ClueLocation) {
    return getPuzzleTypesForLocation(location).filter(
      x => x !== PuzzleType.locationName
    );
  }

  randomizePuzzleType(current: ClueDesign): ClueDesign {
    const puzzleTypes = this.getFilteredPuzzlesForLocation(
      current.solutionLocation
    );

    const puzzleType = this.getRandomPuzzleTypeWithAversion(puzzleTypes, [
      current.puzzleType,
    ]);

    let clueInputs = filterInputsByPuzzleType(
      puzzleType,
      current.solutionLocation.clueInputs
    );

    // If puzzle type is not a riddle, don't use riddles if possible
    if (![PuzzleType.riddle].includes(puzzleType)) {
      const filtered = clueInputs.filter(x => x.type !== ClueInputType.Riddle);
      if (filtered.length > 0) clueInputs = filtered;
    }

    const clueInput = shuffle(clueInputs)[0];

    const clueDesign: ClueDesign = {
      solutionLocation: current.solutionLocation,
      puzzleType,
      clueInput,
    };
    return clueDesign;
  }

  randomClueDesignFromLocation(location: ClueLocation): ClueDesign {
    // Only accept puzzles that are either location name or riddle
    const availablePuzzles = getPuzzleTypesForLocation(location);
    const puzzleType = shuffle(availablePuzzles)[0];

    let clueInputs = filterInputsByPuzzleType(puzzleType, location.clueInputs);

    // If puzzle type is not a riddle, don't use riddles if possible
    if (![PuzzleType.riddle].includes(puzzleType)) {
      const filtered = clueInputs.filter(x => x.type !== ClueInputType.Riddle);
      if (filtered.length > 0) clueInputs = filtered;
    }

    const clueInput = shuffle(clueInputs)[0];

    return {
      solutionLocation: location,
      puzzleType,
      clueInput,
    };
  }
}

export class HardClueGenerator extends ClueGenerator {
  getFilteredPuzzlesForLocation(location: ClueLocation) {
    return getPuzzleTypesForLocation(location).filter(
      x => x !== PuzzleType.locationName
    );
  }

  randomizePuzzleType(current: ClueDesign): ClueDesign {
    const availablePuzzles = this.getFilteredPuzzlesForLocation(
      current.solutionLocation
    );

    const puzzleType = this.getRandomPuzzleTypeWithAversion(availablePuzzles, [
      current.puzzleType,
    ]);

    const clueInputs = filterInputsByPuzzleType(
      puzzleType,
      current.solutionLocation.clueInputs
    );

    const clueInput = shuffle(clueInputs)[0];

    const clueDesign: ClueDesign = {
      solutionLocation: current.solutionLocation,
      puzzleType,
      clueInput,
    };
    return clueDesign;
  }

  randomClueDesignFromLocation(location: ClueLocation): ClueDesign {
    const availablePuzzles = this.getFilteredPuzzlesForLocation(location);
    const puzzleType = shuffle(availablePuzzles)[0];

    const clueInputs = filterInputsByPuzzleType(
      puzzleType,
      location.clueInputs
    );
    const clueInput = shuffle(clueInputs)[0];

    return {
      solutionLocation: location,
      puzzleType,
      clueInput,
    };
  }
}

export const DifficultyGenerators = {
  [Difficulty.Easy]: new EasyClueGenerator(),
  [Difficulty.Medium]: new MediumClueGenerator(),
  [Difficulty.Hard]: new HardClueGenerator(),
};
