import {Table} from '../lib/table';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';
import shuffle from 'lodash/shuffle';
import {ClueInputType, ClueInputLocationName} from '../clue-locations';

const debug = window.location.hostname === 'localhost' && true;

const fontSpacing = 24;
const fontOffsetX = 25;
const fontOffsetY = 100;

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const randomCharacter = () =>
  alphabet[Math.floor(Math.random() * alphabet.length)];

interface Vector2 {
  x: number;
  y: number;
}

interface LetterTablesReturn {
  tables: Table<string>[];
  isLetterTable: Table<boolean>;
}
function getLetterTables(word: string): LetterTablesReturn {
  const noSpaceWord = word.replace(/\s/g, '');
  const positions = new Array<Vector2>();

  for (let x = 0; x < 5; x++) {
    for (let y = 0; y < 5; y++) {
      positions.push({x, y});
    }
  }

  const t1 = new Table<string>(5, 5);
  const t2 = new Table<string>(5, 5);
  const t3 = new Table<string>(5, 5);

  const isLetterTable = new Table<boolean>(5, 5);
  isLetterTable.fill(false);

  const tables = [t1, t2, t3];
  const tablesHaveSameValue = (pos: Vector2) => {
    const values = tables.map(x => x.get(pos));
    return values.every(x => x === values[0]);
  };

  // Fill in random letters for each table
  for (let x = 0; x < 5; x++) {
    for (let y = 0; y < 5; y++) {
      const pos = {x, y};

      while (tablesHaveSameValue(pos)) {
        tables.forEach(x => x.set(pos, randomCharacter()));
      }
    }
  }

  const randomPositions = shuffle(positions);
  for (let i = 0; i < noSpaceWord.length; i++) {
    const position = randomPositions[i];
    const letter = noSpaceWord[i].toUpperCase();

    tables.forEach(x => x.set(position, letter));
    isLetterTable.set(position, true);
  }

  return {
    tables,
    isLetterTable,
  };
}

export class PGLetterGrid extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([ClueInputType.LocationName]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    const input = clueInput as ClueInputLocationName;
    const {tables, isLetterTable} = getLetterTables(input.payload);

    this.clear(ctx);
    this.drawClueNumber(context, context.index);

    ctx.font = this.theme.font;
    ctx.fillStyle = this.theme.textColor;
    ctx.textAlign = 'center';

    const tableXMargin = 150;

    // Draw tables
    for (let t = 0; t < tables.length; t++) {
      const table = tables[t];

      for (let y = 0; y < table.height; y++) {
        for (let x = 0; x < table.width; x++) {
          const letter = table.get({x, y});

          const rx = x * fontSpacing + fontOffsetX + tableXMargin * t;
          const ry = y * fontSpacing + fontOffsetY;

          if (debug && isLetterTable.get({x, y})) {
            ctx.fillStyle = '#888';
            ctx.fillText(letter || 'OOPS', rx, ry);
          } else {
            ctx.fillStyle = '#000';
            ctx.fillText(letter || 'OOPS', rx, ry);
          }
        }
      }
    }

    this.setSmallText(ctx);
    ctx.textAlign = 'left';
    const hintText = "Find what's the same in every group";
    ctx.fillText(hintText, 15, ctx.canvas.height - 20);
  }
}
