import {PGRiddle} from './pg-plaintext';
import {PGRot13} from './pg-rot13';
import {PGA1Z26} from './pg-a1-z26';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';
import {PGWordsearch} from './pg-wordsearch';
import {ClueInput, ClueLocation} from '../clue-locations';
import {PGPigPen} from './pg-pigpen';
import {PGAnagram} from './pg-anagram';
import {PGAtbash} from './pg-atbash';
import {PGLetterGrid} from './pg-letter-grids';
import {PGLocationName} from './pg-location-name';

export enum PuzzleType {
  locationName = 'Location Name',
  anagram = 'Anagram',
  riddle = 'Riddle',
  rot13 = 'Rot 13',
  a1z26 = 'A-1/Z-26',
  crossword = 'Crossword',
  pigPen = 'PigPen',
  atbash = 'Atbash',
  letterGrid = 'Letter Grid',
}

const puzzleGenerators: Record<PuzzleType, PuzzleGenerator> = {
  [PuzzleType.locationName]: new PGLocationName(),
  [PuzzleType.riddle]: new PGRiddle(),
  [PuzzleType.rot13]: new PGRot13(),
  [PuzzleType.a1z26]: new PGA1Z26(),
  [PuzzleType.crossword]: new PGWordsearch(),
  [PuzzleType.pigPen]: new PGPigPen(),
  [PuzzleType.anagram]: new PGAnagram(),
  [PuzzleType.atbash]: new PGAtbash(),
  [PuzzleType.letterGrid]: new PGLetterGrid(),
};

export function filterInputsByPuzzleType(
  puzzleType: PuzzleType,
  inputs: ClueInput[]
): ClueInput[] {
  const allowedInputTypes = puzzleGenerators[puzzleType].allowedInputTypes;
  return inputs.filter(x => allowedInputTypes.has(x.type));
}

export function getPuzzleTypesForLocation(
  clueLocation: ClueLocation
): PuzzleType[] {
  const {clueInputs} = clueLocation;
  return Object.values(PuzzleType).filter(
    x => filterInputsByPuzzleType(x, clueInputs).length > 0
  );
}

export function generateClue(type: string, input: ClueGeneratorInput) {
  const clueGen = puzzleGenerators[type as PuzzleType];
  if (!clueGen) throw new Error(`Clue generator type ${type} not recognized!`);

  clueGen.drawClue(input);
}
