/* eslint-disable @typescript-eslint/no-explicit-any */
import './homepage-scene.css';
import * as THREE from 'three';
import {GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {Vector3} from 'three';

const minimizeOffset = 0.22;

export interface HomepageSceneState {
  state: number;
}

export class HomepageScene {
  gltfLoader = new GLTFLoader();
  textureLoader = new THREE.TextureLoader();
  canvas: HTMLCanvasElement;
  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera();
  renderer!: THREE.WebGLRenderer;
  raycaster = new THREE.Raycaster();
  clock = new THREE.Clock();

  kitchenTex: THREE.Texture;
  kitchenMat: THREE.MeshBasicMaterial;

  // clueTextures = new Array<THREE.Texture>();
  // clues = new Array<any>();

  sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  pointer = new THREE.Vector2();
  cursor = {
    x: 0,
    y: 0,
  };

  hoverObj: any;
  gameState = 0;
  clueMinimized = false;
  targetDestination: Vector3 | undefined;
  animationFrame: number | undefined;

  onStateChange: (state: HomepageSceneState) => void;

  constructor(
    canvas: HTMLCanvasElement,
    onStateChange: (state: HomepageSceneState) => void
  ) {
    this.canvas = canvas;
    this.onStateChange = onStateChange;

    // load textures and materials
    this.kitchenTex = this.textureLoader.load('CulledBakedSRGB.jpg');
    this.kitchenMat = new THREE.MeshBasicMaterial({map: this.kitchenTex});
    this.kitchenTex.flipY = false;
    this.kitchenTex.encoding = THREE.sRGBEncoding;

    // load GLTF
    this.setupListeners();

    this.init().then(() => this.tick());
  }

  async init() {
    const gltf = await this.loadGLTF('ModernKitchenWideCulled.glb');
    this.scene.add(gltf.scene);

    for (let i = 0; i < gltf.scene.children.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const child = gltf.scene.children[i] as any;
      child.material = this.kitchenMat;
    }

    // await this.loadClueTexts();
    // await this.loadClues();
    await this.initLights();
    await this.setupCamera();
    this.renderer = await this.setupRenderer();
  }

  setupListeners() {
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('resize', this.onResize);
    window.addEventListener('click', this.onClick);
  }

  cleanup() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('click', this.onClick);
    if (this.animationFrame) {
      window.cancelAnimationFrame(this.animationFrame);
    }
  }

  async loadGLTF(url: string): Promise<GLTF> {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(url, data => resolve(data), undefined, reject);
    });
  }

  // async loadClueTexts() {
  //   this.clueTextures = [
  //     // this.textureLoader.load('Clue0.jpg'),
  //     this.textureLoader.load('Clue1.jpg'),
  //     this.textureLoader.load('Clue2.jpg'),
  //     this.textureLoader.load('Clue3.jpg'),
  //     this.textureLoader.load('Congratulations.jpg'),
  //   ];

  //   this.clueTextures.forEach(x => {
  //     x.flipY = false;
  //   });
  // }

  // async loadClues() {
  //   const gltf = await this.loadGLTF('Clues.glb');
  //   this.scene.add(gltf.scene);
  //   for (let i = 0; i < gltf.scene.children.length; i++) {
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     const child = gltf.scene.children[i] as any;
  //     child.material = new THREE.MeshBasicMaterial({
  //       map: this.clueTextures[i],
  //     });
  //     this.clues[i] = gltf.scene.children[i];
  //   }
  // }

  async initLights() {
    const light1 = new THREE.DirectionalLight(0xffffff);
    light1.castShadow = true;
  }

  onResize = () => {
    this.sizes.width = window.innerWidth;
    this.sizes.height = window.innerHeight;

    // Update camera
    this.camera.aspect = this.sizes.width / this.sizes.height;
    this.camera.updateProjectionMatrix();

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  };

  onMouseMove = (event: MouseEvent) => {
    this.cursor.x = event.clientX / this.sizes.width - 0.5;
    this.cursor.y = 0.5 - event.clientY / this.sizes.height;
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  async setupCamera() {
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.sizes.width / this.sizes.height,
      0.1,
      100
    );
    this.camera.position.z = 1.5;
    this.scene.add(this.camera);
  }

  setupRenderer() {
    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
    });
    renderer.setSize(this.sizes.width, this.sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputEncoding = THREE.sRGBEncoding;

    return renderer;
  }

  raycast() {
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);
    if (intersects.length > 0) {
      this.hoverObj = intersects[0];
      if (
        intersects[0].object.name === 'Frame' ||
        intersects[0].object.name === 'vase' ||
        intersects[0].object.name === 'Sink' ||
        intersects[0].object.name === 'Mug' ||
        intersects[0].object.name === 'Freezer' ||
        intersects[0].object.name === 'Pot' ||
        intersects[0].object.name === 'Basket'
      ) {
        document.body.style.cursor = 'pointer';
      } else {
        document.body.style.cursor = 'default';
      }
    }
  }

  onClick = () => {
    if (this.hoverObj.object.name.includes('Clue')) {
      this.clueMinimized = !this.clueMinimized;
    }

    const objectName = this.hoverObj.object.name.toLowerCase();
    const stateSolutions: {[index: number]: string} = {
      0: 'vase',
      1: 'mug',
      2: 'frame',
      3: 'sink',
    };

    const solution = stateSolutions[this.gameState];
    if (solution === objectName) {
      this.gameState += 1;
      this.clueMinimized = false;
      this.onStateChange({state: this.gameState});
    }
  };

  // clueLerp(clueNum: number) {
  //   const minimizedPos = new Vector3(
  //     this.camera.position.x - 0.15,
  //     this.camera.position.y - minimizeOffset,
  //     // Math.max(this.camera.position.y - minimizeOffset, -0.4),
  //     this.camera.position.z - 0.25
  //   );
  //   // const minimizedPos = new Vector3(
  //   //   this.camera.position.x + 10,
  //   //   this.camera.position.y + 10,
  //   //   this.camera.position.z + 10
  //   // );
  //   const notMinimizedPos = new Vector3(
  //     this.camera.position.x + 0.15,
  //     this.camera.position.y,
  //     this.camera.position.z - 0.25
  //   );

  //   this.targetDestination = this.clueMinimized
  //     ? minimizedPos
  //     : notMinimizedPos;

  //   this.clues[clueNum].position.lerp(this.targetDestination, 0.1);
  // }

  // clueDismiss = (clueNum: number) => {
  //   if (!this.targetDestination) return;
  //   const dest = new Vector3(
  //     this.targetDestination.x + 2,
  //     this.targetDestination.y,
  //     this.targetDestination.z
  //   );
  //   this.clues[clueNum].position.lerp(dest, 0.02);
  // };

  cameraPan() {
    this.camera.position.x = this.cursor.x / 2;
    this.camera.position.y = this.cursor.y / 2;
  }

  tick() {
    // const currentClue = this.gameState - 1;
    // const previousClue = this.gameState - 2;

    // if (currentClue >= 0) {
    //   this.clueLerp(currentClue);
    // }
    // if (previousClue >= 0) {
    //   this.clueDismiss(previousClue);
    // }

    this.cameraPan();
    this.raycast();
    this.renderer.render(this.scene, this.camera);
    this.animationFrame = window.requestAnimationFrame(this.tick.bind(this));
  }
}
