import create from 'zustand';
import {imageLoader} from '../lib/load-image';
import {PDFFormatter} from '../lib/pdf-formatter';
import {
  Difficulty,
  ClueGenerator,
  DifficultyGenerators,
} from './treasure-hunt-generator';
import {ClueDesign, ClueSummary} from './clue-design';
import {devClues} from './dev-clues';
import ReactGA from 'react-ga4';
import {YouWon} from '../puzzle-generators/you-won';
import {cardHeightInPixels, cardWidthInPixels} from '../lib/constants';

const debug = window.location.hostname === 'localhost' && false;

export const useTreasueHuntDesigner = create<{
  defaultClueNumber: number;
  maxClueNumber: number;
  clues: ClueDesign[];
  difficulty: Difficulty;
  generator: ClueGenerator;
  changeDifficulty: (difficulty: Difficulty) => void;
  randomizeClueTrail: () => void;
  randomizeLocation: (index: number) => void;
  randomizePuzzleType: (index: number) => void;
  removeIndex: (index: number) => void;
  addNewRandomClueDesign: () => void;
  overrideIndex: (index: number, design: ClueDesign) => void;
  randomizeWithDifficulty: (index: number) => void;
  getClueSummaries: () => ClueSummary[];
  generatePDF: () => void;
}>((set, get) => ({
  defaultClueNumber: 6,
  maxClueNumber: 11,
  difficulty: Difficulty.Medium,
  generator: DifficultyGenerators[Difficulty.Medium],
  // The Current Clues being designed
  clues: debug ? devClues : [],
  changeDifficulty(difficulty) {
    set({difficulty, generator: DifficultyGenerators[difficulty]});
  },
  randomizeClueTrail: () => {
    const {defaultClueNumber, generator} = get();
    const clues = generator.randomizeClueTrail(defaultClueNumber);
    set({clues});
  },
  randomizeLocation: (index: number) => {
    const {clues, generator} = get();

    const newClue = generator.randomizeLocation(clues, clues[index]);
    get().overrideIndex(index, newClue);
  },
  randomizePuzzleType: (index: number) => {
    const {clues, generator} = get();

    const newClue = generator.randomizePuzzleType(clues[index]);
    get().overrideIndex(index, newClue);
  },
  removeIndex: (index: number) => {
    set({clues: get().clues.filter((_, i) => i !== index)});
  },
  addNewRandomClueDesign: () => {
    const {clues, generator} = get();
    const newClue = generator.addNewRandomClueDesign(clues);
    set({clues: [...clues, newClue]});
  },
  overrideIndex: (index, design) => {
    const clues = get().clues.map((x, i) => (i === index ? design : x));
    set({clues});
  },
  randomizeWithDifficulty: (index: number) => {
    const {clues, difficulty} = get();
    const existing = clues[index];
    const generator = DifficultyGenerators[difficulty];
    const newDesign = generator.randomizeLocation(clues, existing);

    // ToDo
    get().overrideIndex(index, newDesign);
  },
  getClueSummaries: (): ClueSummary[] => {
    const {clues} = get();
    const clueSummaries = new Array<ClueSummary>();

    const canvases = Array.from(document.getElementsByTagName('canvas'));
    if (canvases.length !== get().clues.length) {
      throw new Error('Found a different number of clues and images');
    }

    for (let i = 0; i < clues.length; i++) {
      const clue = clues[i];
      const lastClue = i === 0 ? undefined : clues[i - 1];
      const clueLocation = lastClue?.solutionLocation.locationName;

      const firstClueText = 'Clue 1 - Give this out to start the game!';
      const locationText = `Clue ${i + 1} - Hide this at ${clueLocation}`;
      const hideMeText = i === 0 ? firstClueText : locationText;

      clueSummaries.push({
        design: clue,
        hideMeText,
        canvas: canvases[i],
      });
    }

    return clueSummaries;
  },
  generatePDF: async () => {
    ReactGA.event({
      action: 'generate-pdf',
      category: 'create-cluetrail',
    });
    const clueSummaries = get().getClueSummaries();
    const canvas = document.createElement('canvas');
    canvas.width = cardWidthInPixels;
    canvas.height = cardHeightInPixels;
    const ctx = canvas.getContext('2d')!;
    await new YouWon().drawClue({
      ctx,
      index: 0,
      clueInput: null!,
    });
    // const youWonImg = await imageLoader.loadImage('/assets/you-won.png');
    const cardImages: string[] = [
      ...clueSummaries.map(x => x.canvas.toDataURL()),
      canvas.toDataURL(),
    ];
    const instructionText = clueSummaries.map(x => x.hideMeText);

    const pdfFormatter = new PDFFormatter();
    pdfFormatter.formatPDF({
      cardImages,
      instructionText,
      fileName: `cluetrail-${new Date().toISOString().slice(0, 10)}`,
    });
  },
}));
