import {shuffle} from 'lodash';
import {ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

export class PGAnagram extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    ClueInputType.LocationName,
    ClueInputType.Riddle,
  ]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    let text = '';
    if (clueInput.type === ClueInputType.LocationName) {
      text = clueInput.payload;
    } else if (clueInput.type === ClueInputType.Riddle) {
      text = clueInput.payload;
    }

    this.clear(ctx);
    this.drawClueNumber(context, context.index);

    // draw test
    const puzzleText = text
      .split(' ')
      .map(word => {
        if (word.length === 1) return word;

        let shuffledWord = word;
        while (shuffledWord === word) {
          const noPunctionation = word.replace(/\W/g, '');
          const letters = noPunctionation.split('');
          const shuffledLetters = shuffle(letters);
          shuffledWord = shuffledLetters.join('');
        }

        return shuffledWord;
      })
      .join(' ');

    ctx.fillStyle = '#000';
    this.setSmallText(ctx);
    this.wrapText(ctx, puzzleText, 20, 100, 400, 20);

    // draw hint
    ctx.fillText('UCLE-> CLUE', 20, ctx.canvas.height - 10);
  }
}
