import {useRef, FC, useEffect} from 'react';
import {generateClue} from '../puzzle-generators';
import {cardHeightInPixels, cardWidthInPixels} from '../lib/constants';
import {ClueDesign} from '../stores/clue-design';

export interface ClueImageProps {
  index: number;
  clueDesign: ClueDesign;
}

export const ClueImage: FC<ClueImageProps> = props => {
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current as unknown as HTMLCanvasElement;
    const ctx = canvas.getContext('2d')!;

    const {clueDesign} = props;
    const {puzzleType, clueInput} = clueDesign;
    generateClue(puzzleType, {
      ctx,
      clueInput,
      index: props.index,
    });
  }, [props.index, props.clueDesign]);

  return (
    <canvas
      ref={canvasRef}
      width={cardWidthInPixels}
      height={cardHeightInPixels}
      style={{
        display: 'block',
        width: '100%',
        maxWidth: `${cardWidthInPixels}px`,
        maxHeight: `${cardHeightInPixels}px`,
        border: '1px solid #ccc',
      }}
    />
  );
};
