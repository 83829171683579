import {ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

const key: {[index: string]: number} = {
  a: 1,
  b: 2,
  c: 3,
  d: 4,
  e: 5,
  f: 6,
  g: 7,
  h: 8,
  i: 9,
  j: 10,
  k: 11,
  l: 12,
  m: 13,
  n: 14,
  o: 15,
  p: 16,
  q: 17,
  r: 18,
  s: 19,
  t: 20,
  u: 21,
  v: 22,
  w: 23,
  x: 24,
  y: 25,
  z: 26,
};

const wordToNumbers = (word: string) => {
  const letters = word.toLowerCase().split('');
  const numbers = letters.map(x => key[x]?.toString() || '').filter(x => x);
  return numbers.join('.');
};
const messageToNumbers = (message: string) => {
  return message
    .split(' ')
    .map(word => wordToNumbers(word))
    .join(' / ');
};

export class PGA1Z26 extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    ClueInputType.LocationName,
    ClueInputType.Riddle,
  ]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    let text = '';
    if (clueInput.type === ClueInputType.LocationName) {
      text = clueInput.payload;
    } else if (clueInput.type === ClueInputType.Riddle) {
      text = clueInput.payload;
    }

    this.clear(ctx);
    this.drawClueNumber(context, context.index);

    // draw test
    const puzzleText = messageToNumbers(text);
    ctx.fillStyle = '#000';
    this.setSmallText(ctx);
    this.wrapText(ctx, puzzleText, 20, 100, 400, 20);

    // draw hint
    ctx.fillText('1 -> A, 2 -> B, etc', 20, ctx.canvas.height - 20);
  }
}
