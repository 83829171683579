import './create-cluetrail.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import {ClueCard} from '../components/ClueCard';
import {useTreasueHuntDesigner} from '../stores/treasure-hunt-designer';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import {Difficulty} from '../stores/treasure-hunt-generator';
import {DifficultyButton} from '../components/DifficultyButton';
import {Typography} from '@mui/material';

export const CreateClueTrail = () => {
  const designer = useTreasueHuntDesigner();

  const canAddMoreClues = designer.clues.length < designer.maxClueNumber;

  return (
    <>
      <div className={'create-cluetrail-page'}>
        {/* Text */}
        <div style={{marginTop: '50px'}}>
          <Typography
            variant="h3"
            component="h1"
            sx={{marginTop: '1em', marginBottom: '0.25em', color: 'Black'}}
          >
            Your Clue Trail
          </Typography>
          <Typography>1. Select a difficulty and generate clues</Typography>
          <Typography>2. Randomize the cards until satisfied</Typography>
          <Typography>
            3. Download, print, cut, and place the clue trail
          </Typography>
        </div>

        {/* Difficulty Buttons */}
        <div style={{marginTop: '2em', display: 'flex', flexWrap: 'wrap'}}>
          <DifficultyButton
            difficulty={Difficulty.Easy}
            text="Easy"
            sx={{marginRight: '5px'}}
          />
          <DifficultyButton
            difficulty={Difficulty.Medium}
            text="Medium"
            sx={{marginRight: '5px'}}
          />
          <DifficultyButton difficulty={Difficulty.Hard} text="Hard" />
          <Button
            id="btn-randomize-all"
            variant="contained"
            sx={{borderRadius: '24px'}}
            onClick={() => designer.randomizeClueTrail()}
          >
            Randomize All
          </Button>
        </div>
      </div>

      <div id="card-box">
        {designer.clues.map((x, i) => (
          <Slide key={i} in={true} direction="up" mountOnEnter={false}>
            <div className="card-box-outside">
              <ClueCard
                key={i}
                index={i}
                clueDesign={designer.clues[i]}
                onRandomize={() => designer.randomizeWithDifficulty(i)}
                onRemoveCard={() => designer.removeIndex(i)}
              />
            </div>
          </Slide>
        ))}

        <Box textAlign="center" sx={{marginBottom: '20px'}}>
          <Button
            disabled={!canAddMoreClues}
            variant="outlined"
            onClick={designer.addNewRandomClueDesign}
            className="clue-card"
            // style={{
            //   width: '350px',
            //   height: '400px',
            // }}
          >
            {canAddMoreClues ? 'Add Another Clue' : 'No More Clues Allowed!'}
          </Button>
        </Box>

        {designer.clues.length > 1 && (
          <div
            style={{
              margin: 0,
              top: 'auto',
              right: 20,
              bottom: 20,
              left: 'auto',
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Fab
              color="primary"
              aria-label="download"
              onClick={() => designer.generatePDF()}
            >
              <DownloadIcon />
            </Fab>
          </div>
        )}
      </div>
    </>
  );
};
