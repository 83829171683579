import {ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

export class YouWon extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([]);

  cachedImage?: HTMLImageElement;
  async loadYouWonImg(): Promise<HTMLImageElement> {
    if (!this.cachedImage) {
      this.cachedImage = new Image();
      this.cachedImage.src = '/assets/party-popper-16x16.png';
      await new Promise(resolve => (this.cachedImage!.onload = resolve));
    }

    return this.cachedImage;
  }

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx} = context;

    this.clear(ctx);
    this.setLargeText(ctx);
    // this.drawClueNumber(context, context.index);

    this.setLargeText(ctx);
    ctx.fillStyle = this.theme.textColor;

    ctx.fillText('CONGRATULATIONS, YOU WON!', 70, ctx.canvas.height / 2 + 35);

    const img = await this.loadYouWonImg();
    ctx.drawImage(img, 230, 115);

    // ctx.fillText('CONGRATULATIONS, YOU WON!', 70, ctx.canvas.height / 2 + 100);

    // const img = await this.loadYouWonImg();
    // ctx.drawImage(img, 190, 70);
  }
}
