import {PuzzleType} from '../puzzle-generators';
import {ClueLocationData} from '../clue-locations';
import {ClueDesign} from './clue-design';

export const devClues = new Array<ClueDesign>(
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[0],
    puzzleType: PuzzleType.pigPen,
  },
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[3],
    puzzleType: PuzzleType.atbash,
  },
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[3],
    puzzleType: PuzzleType.anagram,
  },
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[3],
    puzzleType: PuzzleType.a1z26,
  },
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[4],
    puzzleType: PuzzleType.crossword,
  },
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[3],
    puzzleType: PuzzleType.riddle,
  },
  {
    solutionLocation: ClueLocationData[0],
    clueInput: ClueLocationData[0].clueInputs[3],
    puzzleType: PuzzleType.rot13,
  }
);
