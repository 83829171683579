import {jsPDF} from 'jspdf';
import {cardHeightInInches, cardScale, cardWidthInInches} from './constants';
import chunk from 'lodash/chunk';

export interface PDFFormatterOptions {
  cardImages: Array<string | HTMLImageElement>;
  instructionText: Array<string>;
  fileName: string;
}

export class PDFFormatter {
  async formatPDF(options: PDFFormatterOptions) {
    const {cardImages, instructionText, fileName} = options;
    const doc = new jsPDF({unit: 'in', orientation: 'landscape'});

    // const clueSummaries = get().getClueSummaries();

    const xOffset = 0.75;
    const yOffset = 0.75;
    const w = cardWidthInInches * cardScale;
    const h = cardHeightInInches * cardScale;

    const puzzleCoordinates = new Array<{x: number; y: number}>();

    for (let y = 0; y < 2; y++) {
      for (let x = 0; x < 2; x++) {
        puzzleCoordinates.push({
          x: x * w + xOffset,
          y: y * h + yOffset,
        });
      }
    }

    const pageChunks = chunk(cardImages, 4);
    pageChunks.forEach((cardImages, pageIndex) => {
      if (pageIndex > 0) {
        doc.addPage();
      }

      cardImages.forEach((img, cluePageIndex) => {
        const pc = puzzleCoordinates[cluePageIndex];
        doc.setLineWidth(0.05);
        doc.rect(pc.x, pc.y, w, h);
        doc.addImage(img, 'JPEG', pc.x, pc.y, w, h);
      });
    });

    // Add instruction page
    doc.addPage();
    instructionText.forEach((text, index) => {
      doc.text(text, xOffset, yOffset + 0.25 * index);
    });

    doc.save(`${fileName}.pdf`);
  }
}
