import WebFont from 'webfontloader';
import {ClueInput, ClueInputType} from '../clue-locations';

export interface Theme {
  backgroundURL: string;
  textColor: string;
  font: string;
}

const defaultTheme: Theme = {
  backgroundURL:
    'https://s7d5.scene7.com/is/image/PaperSource/196940224672?resMode=sharp2&op_usm=2,1,25,1&fmt=jpg&qlt=85&fit=constrain,1&wid=600&hei=600',
  textColor: 'black',
  // font: '20px Poppins',
  font: '20px Roboto Mono',
};

export interface ClueGeneratorInput {
  ctx: CanvasRenderingContext2D;
  clueInput: ClueInput;
  index: number;
}

const fontLoad = new Promise<void>(resolve =>
  WebFont.load({
    google: {
      families: ['Roboto Mono', 'Poppins', 'Bebas Neue', 'Lato'],
    },
    custom: {
      families: ['PigPen'],
      urls: ['/fonts.css'],
    },
    active: () => resolve(),
    fontinactive: err => console.error(err),
  })
);

export abstract class PuzzleGenerator {
  theme = defaultTheme;

  // Allow only certain input types
  abstract allowedInputTypes: Set<ClueInputType>;

  // Fonts
  largeTextSize = 38;
  largeText = `${this.largeTextSize}px Bebas Neue`;

  setLargeText(ctx: CanvasRenderingContext2D): void {
    ctx.font = this.largeText;
  }

  smallTextSize = 20;
  smallText = `${this.smallTextSize}px Bebas Neue`;

  setSmallText(ctx: CanvasRenderingContext2D): void {
    ctx.font = this.smallText;
  }

  smallMonoText = `${this.smallTextSize}px Roboto Mono`;
  setSmallMonoText(ctx: CanvasRenderingContext2D): void {
    ctx.font = this.smallMonoText;
  }

  pigPenFont = `${this.smallTextSize}px PigPen`;
  setPigPenFont(ctx: CanvasRenderingContext2D): void {
    ctx.font = this.pigPenFont;
  }

  protected async WaitForFontLoad() {
    await fontLoad;
  }

  wrapText(
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number
  ) {
    const cars = text.split('\n');

    for (let ii = 0; ii < cars.length; ii++) {
      let line = '';
      const words = cars[ii].split(' ');

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth) {
          context.fillText(line, x, y);
          line = words[n] + ' ';
          y += lineHeight;
        } else {
          line = testLine;
        }
      }

      context.fillText(line, x, y);
      y += lineHeight;
    }
  }

  async clear(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  drawVertLine(context: ClueGeneratorInput) {
    const {ctx} = context;

    const ch = ctx.canvas.height;

    const xOffset = 190;

    ctx.save();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#aaa ';
    ctx.beginPath();
    ctx.moveTo(xOffset, 0);
    ctx.lineTo(xOffset, ch);
    ctx.stroke();

    ctx.restore();
  }

  drawClueNumber(context: ClueGeneratorInput, number: number): void {
    const {ctx} = context;

    ctx.save();
    ctx.font = this.theme.font;
    ctx.fillStyle = this.theme.textColor;
    this.setLargeText(ctx);
    ctx.textAlign = 'left';
    ctx.fillText(`CLUE #${number + 1}`, 15, 45);
    ctx.restore();
  }

  drawWatermark(context: ClueGeneratorInput) {
    const {ctx} = context;
    ctx.save();

    const cw = ctx.canvas.width;
    const ch = ctx.canvas.height;

    const padding = 15;

    this.setSmallMonoText(ctx);
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(cw - padding, padding);
    ctx.lineTo(cw - padding, 100);
    ctx.moveTo(cw - padding, 220);
    ctx.lineTo(cw - padding, ch - padding);
    ctx.stroke();

    // ctx.moveTo(100, 100);
    ctx.rotate(-Math.PI / 2);

    ctx.textAlign = 'center';
    ctx.fillText('cozyclues', -160, 494);

    ctx.restore();
  }

  protected abstract _drawClue(context: ClueGeneratorInput): Promise<void>;

  async drawClue(context: ClueGeneratorInput): Promise<void> {
    await this.WaitForFontLoad();

    context.ctx.save();
    await this._drawClue(context);
    context.ctx.restore();
    this.drawWatermark(context);
  }
}
