import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Root} from './routes/root';
import {CreateClueTrail} from './routes/create-cluetrail';
import {HomePage} from './routes/homepage';
import {createTheme, ThemeProvider} from '@mui/material';
import {Colors} from './colors';
import ReactGA from 'react-ga4';

const GA_ID_PROD = 'G-QC4FBYJ0YP';
const GA_ID_DEV = 'G-VQ96GCNPS3';

const GA_ID =
  window.location.hostname === 'cozyclues.com' ? GA_ID_PROD : GA_ID_DEV;

ReactGA.initialize(GA_ID);

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.DarkBlue,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
    ],
  },
  {
    path: '/create-cluetrail',
    element: <Root />,
    children: [
      {
        path: '/create-cluetrail',
        element: <CreateClueTrail />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
