import ClueLocationDataJSON from './data/clue-locations.json';

export enum ClueInputType {
  LocationName = 'LOCATION_NAME',
  ShortWords = 'SHORT_WORDS',
  Riddle = 'RIDDLE',
  FillInTheBlanks = 'FILL_IN_THE_BLANKS',
}

export interface ClueLocationDTO {
  id: string;
  locationName: string;
  riddles?: {text: string}[];
  fillInTheBlanks?: {text: string}[];
}

export interface ClueInputLocationName {
  type: ClueInputType.LocationName;
  answerDisplayText: string;
  payload: string;
}

export interface ClueInputRiddle {
  type: ClueInputType.Riddle;
  answerDisplayText: string;
  payload: string;
}

export interface ClueInputFillInTheBlanks {
  type: ClueInputType.FillInTheBlanks;
  answerDisplayText: string;
  payload: {
    displayText: string;
    blanksText: string;
    blanksWords: string[];
  };
}

export interface ClueInputShortWords {
  type: ClueInputType.ShortWords;
  answerDisplayText: string;
  payload: string[];
}

export type ClueInput =
  | ClueInputLocationName
  | ClueInputRiddle
  | ClueInputFillInTheBlanks
  | ClueInputShortWords;

export interface ClueLocation {
  id: string;
  locationName: string;
  clueInputs: ClueInput[];
}

function DTOGetClueInputLocationName(
  dto: ClueLocationDTO
): ClueInputLocationName[] {
  return [
    {
      type: ClueInputType.LocationName,
      answerDisplayText: dto.locationName,
      payload: dto.locationName,
    },
  ];
}

function DTOGetClueInputRiddles(dto: ClueLocationDTO): ClueInputRiddle[] {
  const riddles = dto.riddles || [];
  return riddles.map(x => ({
    type: ClueInputType.Riddle,
    answerDisplayText: x.text,
    payload: x.text,
  }));
}

function DTOGetClueInputFillInTheBlanks(
  dto: ClueLocationDTO
): ClueInputFillInTheBlanks[] {
  const fillInTheBlanks = dto.fillInTheBlanks || [];

  return fillInTheBlanks.map(x => {
    const displayText = x.text.replace(/\$/g, '');
    const blanksText = x.text
      .split(' ')
      .map(x => {
        if (!x.startsWith('$')) return x;

        return x.replace(/[$0-9a-z]/gi, '_');
      })
      .join(' ');
    const blanksWords = x.text
      .split(' ')
      .filter(x => x.startsWith('$'))
      .map(x => x.substring(1))
      .map(x => x.replace(/\W/g, ''));

    return {
      type: ClueInputType.FillInTheBlanks,
      answerDisplayText: displayText,
      payload: {
        displayText,
        blanksText,
        blanksWords,
      },
    };
  });
}

function DTOGetClueInputsShortWords(
  dto: ClueLocationDTO
): ClueInputShortWords[] {
  const words = dto.locationName.split(' ');
  if (words.every(x => x.length <= 10)) {
    return [
      {
        type: ClueInputType.ShortWords,
        answerDisplayText: words.join(' '),
        payload: words,
      },
    ];
  } else {
    return [];
  }
}

function parseClueLocationData(data: ClueLocationDTO[]): ClueLocation[] {
  const locationData = new Array<ClueLocation>();

  for (const dto of data) {
    const id = dto.id;
    const locationName = dto.locationName;

    const clueInputs = new Array<ClueInput>(
      ...DTOGetClueInputLocationName(dto),
      ...DTOGetClueInputRiddles(dto),
      ...DTOGetClueInputFillInTheBlanks(dto),
      ...DTOGetClueInputsShortWords(dto)
    );

    locationData.push({
      id,
      locationName,
      clueInputs,
    });
  }

  return locationData;
}

export const ClueLocationData = parseClueLocationData(ClueLocationDataJSON);
