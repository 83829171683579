import Button from '@mui/material/Button';
import {useTreasueHuntDesigner} from '../stores/treasure-hunt-designer';
import {Difficulty} from '../stores/treasure-hunt-generator';
import {Colors} from '../colors';
import {SxProps} from '@mui/material';

interface DifficultyButtonProps {
  difficulty: Difficulty;
  text: string;
  sx?: SxProps;
}

const fromColor = (color: string) => ({
  outlined: {
    color: color,
    borderColor: color,
    '&:hover': {
      borderColor: color,
    },
  },
  contained: {
    color: 'white',
    backgroundColor: color,
    borderColor: color,
    '&:hover': {
      backgroundColor: color,
    },
  },
});

const styles = {
  [Difficulty.Easy]: fromColor(Colors.Green),
  [Difficulty.Medium]: fromColor(Colors.Orange),
  [Difficulty.Hard]: fromColor(Colors.Red),
};

export const DifficultyButton: React.FC<DifficultyButtonProps> = (
  props: DifficultyButtonProps
) => {
  const {difficulty} = props;
  const designer = useTreasueHuntDesigner();

  const isSelected = designer.difficulty === difficulty;
  const variant = isSelected ? 'contained' : 'outlined';

  const sx = styles[difficulty][variant];
  return (
    <Button
      variant={variant}
      sx={{
        width: '100px',
        ...sx,
        ...props.sx,
      }}
      onClick={() => designer.changeDifficulty(props.difficulty)}
    >
      {props.text}
    </Button>
  );
};
