import {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {HomepageScene} from '../components/homepage-scene';
import './homepage.css';

const stateImageLookup = new Map<number, string>([
  [0, '/Clue0.jpg'],
  [1, '/Clue1.jpg'],
  [2, '/Clue2.jpg'],
  [3, '/Clue3.jpg'],
  [4, '/Congratulations.jpg'],
]);

export const HomePage = () => {
  const canvas = useRef(null);
  const [showStageA, setShowStageA] = useState(true);
  const [showStageB, setShowStageB] = useState(false);
  const [currentState, setCurrentState] = useState(-1);

  const AtoBTransition = () => {
    setShowStageA(false);
    setTimeout(() => setShowStageB(true), 1000);
  };

  const BtoCTransition = () => {
    setShowStageB(false);
    setTimeout(() => setCurrentState(0), 1000);
  };

  const currentCardImage = stateImageLookup.get(currentState);
  const showFinalButtons = currentState === 4;

  useEffect(() => {
    if (canvas.current === null) return;
    // if (scene !== undefined) return;

    const scene = new HomepageScene(canvas.current, ({state}) => {
      setCurrentState(state);
    });

    return () => scene.cleanup();
  }, []);

  return (
    <div>
      <canvas ref={canvas} className="webgl" style={{zIndex: -10}}></canvas>

      {/* Initial Text */}
      <div
        className={`landing-page ${showStageA ? 'visible' : 'hidden'}`}
        style={{zIndex: 10}}
      >
        <div className="hero-text">
          <h1>Turn your home into an escape room</h1>
          <h6>
            Create a treasure hunt for children that can be played entirely
            within your own home.
          </h6>
          <div style={{display: 'flex'}}>
            <Link to="create-cluetrail">
              <button className="get-started">Get Started</button>
            </Link>
            <button className="see-how-it-works" onClick={AtoBTransition}>
              See How It Works
            </button>
          </div>
        </div>
      </div>

      {/* Hunt Start Text */}
      <div
        className={`landing-page ${showStageB ? 'visible' : 'hidden'}`}
        style={{zIndex: 10}}
      >
        <div className="hero-text">
          <h1>Click on the location in the scene to find each clue!</h1>
          <button
            className="see-how-it-works"
            style={{marginLeft: '0'}}
            onClick={BtoCTransition}
          >
            Gimme the first clue
          </button>
        </div>
      </div>

      <div className="home-clue-card-box">
        <img
          key={currentCardImage}
          className="home-clue-card"
          src={currentCardImage}
        />
        <Link
          to="create-cluetrail"
          style={{pointerEvents: showFinalButtons ? 'auto' : 'none'}}
        >
          <button
            className={`get-started ${
              showFinalButtons ? 'visible2' : 'hidden'
            }`}
            style={{marginLeft: '0'}}
          >
            Make Your Own Clue Trail
          </button>
        </Link>
      </div>
    </div>
  );
};
