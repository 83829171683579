import {FC} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CasinoIcon from '@mui/icons-material/Casino';
import DeleteIcon from '@mui/icons-material/Delete';
import {ClueImage} from './ClueImage';
import {ClueDesign} from '../stores/clue-design';
import {PuzzleType} from '../puzzle-generators';
import {Colors} from '../colors';
import {Typography} from '@mui/material';

import './ClueCard.css';

export const ClueCard: FC<{
  index: number;
  clueDesign: ClueDesign;
  onRemoveCard: () => void;
  onRandomize: () => void;
}> = props => {
  const {onRandomize, onRemoveCard} = props;
  const {solutionLocation, clueInput, puzzleType} = props.clueDesign;

  const showRiddleAnswer =
    ![PuzzleType.locationName, PuzzleType.riddle].includes(puzzleType) &&
    clueInput.answerDisplayText !== solutionLocation.locationName;

  return (
    <Card variant="outlined" className="clue-card">
      <Box sx={{margin: '30px'}}>
        <div className="clue-card_title-box">
          <div>
            <h2 className="clue-card_title">{solutionLocation.locationName}</h2>
          </div>
          <div style={{flex: 1}} />
          <IconButton
            style={{
              color: Colors.Red,
              marginTop: '-5px',
            }}
            onClick={onRandomize}
          >
            <CasinoIcon />
          </IconButton>
          <IconButton
            style={{
              color: Colors.Red,
              marginTop: '-5px',
            }}
            onClick={onRemoveCard}
          >
            <DeleteIcon />
          </IconButton>
        </div>

        <ClueImage clueDesign={props.clueDesign} index={props.index} />

        {showRiddleAnswer && (
          <>
            <Typography className="clue-card_answer">Riddle Answer</Typography>
            <Typography className="clue-card_answer-text">
              {clueInput.answerDisplayText}
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};
