import {Outlet, Link, useLocation} from 'react-router-dom';
import {Colors} from '../colors';

export function Root() {
  const location = useLocation();
  const isHomepage = location.pathname === '/';

  return (
    <>
      <div className="nav-container">
        <div className="navbar">
          <Link to="/" style={{textDecoration: 'none'}}>
            <div
              className="logo"
              style={{
                color: isHomepage ? 'white' : Colors.DarkBlue,
              }}
            >
              cozyclues
            </div>
          </Link>

          {isHomepage && (
            <Link to="create-cluetrail">
              <button className="create">create a trail</button>
            </Link>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
}
