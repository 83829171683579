import {ClueInput, ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

function getClueText(clueInput: ClueInput): string {
  switch (clueInput.type) {
    case ClueInputType.LocationName:
      return clueInput.payload;
    case ClueInputType.Riddle:
      return clueInput.payload;
    case ClueInputType.ShortWords:
      return clueInput.payload.join(' ');
    default:
      throw new Error(`Clue Type ${clueInput.type} not allowed for puzzle.`);
  }
}
export class PGRiddle extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    // ClueInputType.LocationName,
    ClueInputType.Riddle,
    // ClueInputType.ShortWords,
    // ClueInputType.FillInTheBlanks,
  ]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    const text = getClueText(clueInput);

    this.clear(ctx);

    this.drawClueNumber(context, context.index);
    this.setSmallText(ctx);

    ctx.fillStyle = this.theme.textColor;
    this.wrapText(ctx, text, 15, 100, 300, 20);
  }
}
