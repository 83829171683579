import {ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

const rot13 = (message: string) => {
  const originalAlpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const cipher = 'nopqrstuvwxyzabcdefghijklmNOPQRSTUVWXYZABCDEFGHIJKLM';
  return message.replace(
    /[a-z]/gi,
    letter => cipher[originalAlpha.indexOf(letter)]
  );
};

export class PGRot13 extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    ClueInputType.LocationName,
    ClueInputType.Riddle,
  ]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    this.clear(ctx);
    this.setSmallText(ctx);
    this.drawClueNumber(context, context.index);

    let text = '';
    if (clueInput.type === ClueInputType.LocationName) {
      text = clueInput.payload;
    } else if (clueInput.type === ClueInputType.Riddle) {
      text = clueInput.payload;
    } else {
      throw new Error(`Clue Type ${clueInput.type} not recognized`);
    }

    ctx.fillStyle = this.theme.textColor;
    this.wrapText(ctx, rot13(text), 15, 100, 300, this.smallTextSize);
    ctx.fillText('A = N, B = O', 20, ctx.canvas.height - 10);
  }
}
