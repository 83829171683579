import {ClueInputType} from '../clue-locations';
import {PuzzleGenerator, ClueGeneratorInput} from './puzzle-generator';

const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const backwardsAlphabet = alphabet.split('').reverse().join('');

const convertLetter = (letter: string): string => {
  const isUppercase = letter.toUpperCase() === letter;
  const index = alphabet.indexOf(letter.toLowerCase());
  if (index >= 0) {
    const l = backwardsAlphabet[index];
    return isUppercase ? l.toUpperCase() : l;
  }

  return letter;
};

const encryptMessage = (message: string) => {
  return message
    .split('')
    .map(x => convertLetter(x))
    .join('');
};

export class PGAtbash extends PuzzleGenerator {
  allowedInputTypes = new Set<ClueInputType>([
    ClueInputType.LocationName,
    ClueInputType.Riddle,
  ]);

  async _drawClue(context: ClueGeneratorInput): Promise<void> {
    const {ctx, clueInput} = context;

    let text = '';
    if (clueInput.type === ClueInputType.LocationName) {
      text = clueInput.payload;
    } else if (clueInput.type === ClueInputType.Riddle) {
      text = clueInput.payload;
    }

    this.clear(ctx);
    this.drawClueNumber(context, context.index);

    // draw test
    const puzzleText = encryptMessage(text);
    ctx.fillStyle = '#000';
    this.setSmallText(ctx);
    this.wrapText(ctx, puzzleText, 20, 100, 400, 20);

    // draw hint
    ctx.fillText('A -> Z, B -> Y, C -> X, etc', 20, ctx.canvas.height - 20);
  }
}
